import React from 'react'
import * as styles from './contactInfo.module.scss'

export default function ContactInfo( props ) {

    const {title, description, svgLogo} = props
    const {fileName, url} = svgLogo?.file || {}
	
    return (
        <div className = {styles.container}>
            <img 
                alt = {fileName || ""} 
                src = { url || "" }
            />
            <div>
                <h3> { title || "" } </h3>
                <p>  { description || ""} </p>
            </div>

        </div>
    )
}
