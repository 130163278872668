import React, {useEffect, useRef} from 'react'
import Layout from '../../layout/Layout'
import { useStaticQuery, graphql } from 'gatsby'
import * as styles from './contactPage.module.scss'
import ContactForm from '../../components/ContactForm/ContactForm'
import {Link} from 'gatsby'
import { gsap, Power3} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import { OutboundLink } from "gatsby-plugin-google-gtag"
import ContactInfo from '../../components/contactPage/ContactInfo/ContactInfo'

gsap.registerPlugin( ScrollTrigger )

export default function Contact() {

  let contactPage = useRef(null)

  useEffect( ()=>{
    gsap.from(contactPage,{
      scrollTrigger:{
          trigger: contactPage,
          start: 'top 90%',
          end: 'bottom 70%',
          toggleActions: 'play none none none',
      },
      opacity: 0,
      y:100,
      duration:1.5,
      ease:Power3.easeInOut,
  })
},[])

  // init data
  const {contentfulContactPage} = useStaticQuery(getContactPageQuery)
  const {headerTitle, metaTitle, components, content} = contentfulContactPage

  // init contactInfo List
  const contactInfos = components.map( data => {

    // console.log(data)
      const { __typename } = data

      if(__typename === "ContentfulContactUsInfo") {
        return <ContactInfo {...data} key = {data.title} />
      }

      if(__typename === "ContentfulLinks"){
        const { slug, title, isExternal, url } = data

        if(isExternal){
          return <OutboundLink href = {url} key = {title} > {title} </OutboundLink>
        }

        if(!isExternal){
          return <Link to = {slug} key = {title} > {title} </Link>
        }
      }

      return ""

    })

	console.log(content.childMarkdownRemark.html);

    // jsx
    return (
        <div  className = {styles.contactPageLayout}> 
            <Layout isBannerDisabled = {true}
                    title = {headerTitle}
					metaTitle = {metaTitle}
                    >

              <section className = {styles.contactPage}
                       ref = { el => { contactPage = el }}>
              
                <div className = {styles.contactFormContainer}>
                  <ContactForm isContactPage = {true} />
                </div>

                <div className = {styles.contactInfoContainer}>
                  {contactInfos}
				  <div dangerouslySetInnerHTML={{__html: content.childMarkdownRemark.html}}></div>
                </div>

              </section > 

            </Layout>
        </div>
      )
}

// graphql query contentful
const getContactPageQuery = graphql`
query contactPage {
  contentfulContactPage {
    headerTitle
	metaTitle
    components {
      ... on ContentfulContactUsInfo {
        svgLogo {
          file {
            fileName
            url
          }
        }
        description
        title
        __typename
      }
      ... on ContentfulLinks {
        isExternal
        slug
        title
        __typename
      }
    }
	content {
		childMarkdownRemark {
		  html
		}
	  }
  }
  }
`


